
.App {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100vh;
  flex-direction: row;
	/* overflow-x: hidden; */
}

.navbar-custom {
  font-size: medium;
	/* scroll-snap-align: start; */
}

.card, .aboutimg, .social-icon {
	box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px;
  transition: all .3s ease-in-out;
}
.skills-icon {
	filter: drop-shadow( rgba(0, 0, 0, 0.06) 0px 2px 4px);
  transition: all .3s ease-in-out;
}

.card:hover, .aboutimg:hover, .social-icon:hover {
  box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 25px;
  transform: translate3d(0px, -5px, 0px) scale(1.05);
}

.skills-icon:hover {
  filter: drop-shadow(rgba(0, 0, 0, 0.22) 0px 19px 10px);
  transform: translate3d(0px, -5px, 0px) scale(1.05);
}

.navbar__link {
  margin-left: 0.75em;
  margin-right: 0.75em;
  font-size: 1em;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: .1em;
  text-indent: .3em;
  border-bottom: 3px solid transparent;
}

.navbar__link::after {
  transition: all ease-in-out .2s;
  background: none repeat scroll 0 0;
  content: "";
  display: block;
  margin-top: 2px;
  height: 3px;
  width: 0;
}

.navbar__link:hover::after {
  visibility: visible;
  width: 100%;
}

.navbar__link--active::after {
  transition: all ease-in-out .2s;
  width: 100%;
}

.navbar__link--active:hover::after {
  width: 100%;
	
}

.MainApp {
  /* margin-top: 8vh; */
  height: 100vh;
	width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
	/* width: 100vw; */
	/* scroll-padding-top: 15vh; */
	/* overflow-y: scroll;
	scroll-behavior: smooth;
	scroll-snap-type: y proximity; */
}

.section-content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
	margin-top: 6%;
	margin-bottom: 6%;
	/* border-top: 1px solid; */
	/* min-height: 100vh !important; */
	/* scroll-snap-align: start; */
}

.section-content-container-home {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
	/* margin-top: 60px; */
	/* margin-bottom: 60px; */
	min-height: 100vh !important;
	/* scroll-snap-align: start; */
}

.Typewriter {
  display: inline-block;
}

.Typewriter__wrapper {
  font-size: xx-large;
  font-weight: bold;
}

.Typewriter__cursor {
  font-size: xx-large;
  font-weight: bold;
}

section.timeline-card-content {
  justify-content: center;
}

section.timeline-card-content p.card-title {
  font-size: 1.5em;
  margin-top: 25px;
}

section.timeline-card-content p.card-sub-title {
  font-size: 1.25em;
  margin-top: 10px;
}

section.timeline-card-content div.card-description > p {
  font-size: 1.1em;
  margin-top: 10px;
  font-weight: 500;
}

.social {
  position: relative;
  /* margin-top: 60px; */
}


@media only screen and (min-width: 768px) and (max-width: 992px) {

  .navbar__link {
    margin-left: 0.4em;
    margin-right: 0.4em;
    font-size: 0.9em;
    cursor: pointer;
    letter-spacing: .1em;
    text-indent: .2em;
  }

}

@media (max-width: 768px) {

  .navbar__link::after {
    background: none;
    content: "";
    display: none;
    margin-top: 0;
    height: 0;
    width: 0;
  }

  .navbar__link--active::after {
    width: 0;
  }
  
  .navbar__link--active:hover::after {
    width: 0;
  }

  .navbar__link:hover::after {
    width: 0;
  }
}

.header {
  font-size: 3em;
  font-weight: 500;
  padding-bottom: 6%;
}

/* .footer {
	scroll-snap-align: center;
} */
